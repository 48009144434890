<template>
  <div id="footer">
    <div class="container-fluid pt-5">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-5">
            <img class="img-fluid mb-4" src="~@/assets/images/new/homeplus-logo.png">
            <p class="desc">A-2-23, Aurora Place Bukit Jalil, <br>Persiaran Jalil 1, <br>Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur</p>
            <p class="desc">
              <strong>Contact Details:</strong><br>
              +6010 385 0891<br>
              consult.homeplus@gmail.com
            </p>

            <p>
              <strong>Operating Hours:</strong><br>
              Mon to Sun | 10am - 8pm
            </p>
            <ul class="social-list">
              <li><a target="_blank" href="https://www.facebook.com/homeplussmartliving"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a target="_blank" href="https://www.instagram.com/homeplussmarthome/"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
            </ul>
          </div>
          <div class="col-12 col-sm-4">
            <h1 class="title">Sitemap</h1>
            <ul class="menu-list">
              <li><a href="/">Home</a></li>
              <li><a href="https://shop.homeplus2u.com/about-us-2/">About Us</a></li>
              <li><a href="https://shop.homeplus2u.com/">Products</a></li>
              <li><a href="./catalogue.pdf">Catalogue</a></li>
              <li><a href="https://shop.homeplus2u.com/contact/">Contact</a></li>
            </ul>
          </div>
          <div class="col-12 col-sm-3">
            <h1 class="title mb-3">We Accept</h1>
            <div class="row mb-3">
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/visa_logo.png">
              </div>
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/mastercard_logo.png">
              </div>
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/JCB_logo.png">
              </div>
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/fpx_logo.png">
              </div>
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/boost_logo.png">
              </div>
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/UnionPay_logo.png">
              </div>
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/maybankqrpay_logo.png">
              </div>
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/touchngo_logo.png">
              </div>
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/shopeepay_logo.png">
              </div>
            </div>

            <h1 class="title mb-3">Available Installment</h1>
            <div class="row mb-3">
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/publicbank_logo.png">
              </div>
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/maybank_logo.png">
              </div>
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/hongleong_logo.png">
              </div>
            </div>

            <h1 class="title mb-3">Delivery Partners</h1>
            <div class="row mb-3">
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/poslaju-logo.png">
              </div>
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/dhl_logo.png">
              </div>
              <div class="col-3 mb-2">
                <img class="img-fluid" src="~@/assets/images/new/logo/jnt_logo.jpg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <p class="mark">@2022 BY MK PERWAJA SDN BHD.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MyFooter"
}
</script>

<style scoped>

</style>
