<template>
  <div id="home">
    <section class="banner-section wow fadeInDown">
      <carousel class="d-none d-sm-block" :autoplay="true" :autoplay-hover-pause="false" :per-page="1" pagination-active-color="#424242" pagination-color="#fff" :mouse-drag="false" :navigation-enabled="true" :navigation-next-label="navigationNext" :navigation-prev-label="navigationPrev">
        <slide style="position: relative">
          <img class="img-fluid" src="~@/assets/images/new/main-banner-1.jpg">
          <div class="row">
            <div class="col-12 slide-1-btn-col">
              <button class="btn btn-link white-inverse-btn" data-toggle="modal" data-target="#formModal">Ask Us Anything</button>
            </div>
          </div>
        </slide>
        <slide style="position: relative">
          <img class="img-fluid" src="~@/assets/images/new/main-banner-2.jpg">
          <div class="row">
            <div class="col-12 slide-2-btn-col">
              <button class="btn btn-link white-inverse-btn" data-toggle="modal" data-target="#formModal">Ask Us Anything</button>
            </div>
          </div>
        </slide>
        <slide style="position: relative">
          <img class="img-fluid" src="~@/assets/images/new/main-banner-3.jpg">
          <div class="row">
            <div class="col-12 slide-3-btn-col">
              <button class="btn btn-link white-inverse-btn" data-toggle="modal" data-target="#formModal">Ask Us Anything</button>
            </div>
          </div>
        </slide>
      </carousel>

      <carousel class="d-block d-sm-none d-md-none" :autoplay="true" :autoplay-hover-pause="false" :per-page="1" :pagination-enabled="false" :center-mode="false" :scroll-per-page="true" :navigation-enabled="true" :pagination-active-color="'#0b743b'">
        <slide style="position: relative">
          <img class="img-fluid" src="~@/assets/images/new/Homeplus_landing_05.jpg">
          <div class="row">
            <div class="col-12 banner-cont">
              <div class="row">
                <div class="col-12">
                  <h2>Smart Connected</h2>
                  <p>A7H Smart Lock</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 slide-1-btn-col">
              <button class="btn btn-link white-inverse-btn" data-toggle="modal" data-target="#formModal">Ask Us Anything</button>
            </div>
          </div>
        </slide>
        <slide style="position: relative">
          <img class="img-fluid" src="~@/assets/images/new/Homeplus_landing_02.jpg">
          <div class="row">
            <div class="col-12 banner-cont">
              <div class="row">
                <div class="col-12">
                  <h2>LifeSmart</h2>
                  <p>Indoor Camera</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 slide-2-btn-col">
              <button class="btn btn-link white-inverse-btn" data-toggle="modal" data-target="#formModal">Ask Us Anything</button>
            </div>
          </div>
        </slide>
        <slide style="position: relative">
          <img class="img-fluid" src="~@/assets/images/new/Homeplus_landing_03.jpg">
          <div class="row">
            <div class="col-12 banner-cont">
              <div class="row">
                <div class="col-12">
                  <h2>LifeSmart <br><b>Nature Series</b> <br> Onscreen Control</h2>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 slide-3-btn-col">
              <button class="btn btn-link white-inverse-btn" data-toggle="modal" data-target="#formModal">Ask Us Anything</button>
            </div>
          </div>
        </slide>
      </carousel>
    </section>
    <section class="section-one pt-5 wow fadeInDown" data-wow-delay="0.2s">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="subtitle text-center">Welcome to Home+ Smart Home System</p>
            <h1 class="title text-center">AI smart house <b>connecting <br> the home of future</b></h1>
            <p class="subtitle text-center">Go smart do smart look smart</p>
          </div>
        </div>
      </div>
    </section>

    <!--<section class="section-one pt-5 pb-5 wow fadeInDown text-center" data-wow-delay="0.2s" style="background-color: #424242">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="title text-center text-white">Free Consultation</h1>
            <p class="subtitle text-center text-white">Feel Free to Chat With Us</p>
            <button class="btn btn-link white-inverse-btn" data-toggle="modal" data-target="#formModal">Ask Us Anything</button>
          </div>
        </div>
      </div>
    </section>-->

    <!-- Modal -->
    <div class="modal fade" id="formModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4 class="text-center">Please provide us your information</h4>
            <form id="enquiryForm" v-on:submit.prevent="submitForm">
              <div class="form-group">
                <label for="name">Name</label>
                <input type="text" class="form-control" name="username" id="name" required>
              </div>
              <div class="form-group">
                <label for="email">Email Address</label>
                <input type="email" class="form-control" name="email" id="email" required>
              </div>
              <div class="form-group">
                <label for="phone_no">Phone No</label>
                <input type="text" class="form-control" name="phone_no" id="phone_no" required>
              </div>
              <div class="form-group">
                <label for="message">What do you want to ask?</label>
                <textarea type="text" class="form-control" name="message" id="message"></textarea>
              </div>
              <button type="submit" class="btn btn-primary" v-if="!isSubmit">Submit</button>
              <button class="btn btn-primary" type="button" disabled v-if="isSubmit">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Loading...</span>
              </button>
              <div class="alert mt-3" v-bind:class="[ success ? 'alert-success' : 'alert-danger']" role="alert" v-if="message">
                {{ message }}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <section class="section-two pt-5" style="background-color: #fff">
      <div class="container">
        <div class="row mb-4 wow fadeInDown" data-wow-delay="0.2s">
          <div class="col-12 text-center">
            <h2 class="sub-title">What is Smart Home</h2>
            <p class="desc">A smart house is a house that uses the latest electronic technologies and <br class="d-none d-sm-block">
              computerisation to control or automate any number of different aspects of the house. <br class="d-none d-sm-block">
              To build a smart house requires some basic thoughts on design and technology.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">

            <div class="row mb-0 mb-sm-3 wow fadeInDown" data-wow-delay="0.2s" style="background-color: #282828">
              <div class="col-12 col-sm-6 text-center my-auto order-2 order-sm-1 p-5 p-sm-5">
                <h2 class="sub-title text-white">Convenience</h2>
                <p class="desc text-white">Smart home can help to simplify complex tasks,
                  or to automate them completely</p>
              </div>
              <div class="col-12 col-sm-6 p-0 order-1 order-sm-2">
                <img class="img-fluid" src="~@/assets/images/new/banner-1.png">
              </div>
            </div>

            <div class="row mb-0 mb-sm-3 wow fadeInDown" data-wow-delay="0.4s" style="background-color: #282828">
              <div class="col-12 col-sm-6 p-0">
                <img class="img-fluid" src="~@/assets/images/new/banner-2.png">
              </div>
              <div class="col-12 col-sm-6 text-center my-auto p-5 p-sm-5">
                <h2 class="sub-title text-white">Comfort</h2>
                <p class="desc text-white">Climate and lighting systems can be automatically adjusted according to the time of day, the outside temperature or your individual mood.</p>
              </div>
            </div>

            <div class="row mb-0 mb-sm-3 wow fadeInDown" data-wow-delay="0.6s" style="background-color: #282828">
              <div class="col-12 col-sm-6 text-center my-auto order-2 order-sm-1 p-5 p-sm-5">
                <h2 class="sub-title text-white">Security</h2>
                <p class="desc text-white">Using sensors and communications technologies, homes can be carefully and constantly monitored from anywhere.</p>
              </div>
              <div class="col-12 col-sm-6 p-0 order-1 order-sm-2">
                <img class="img-fluid" src="~@/assets/images/new/banner-3.png">
              </div>
            </div>

            <div class="row mb-5 wow fadeInDown" data-wow-delay="0.8s" style="background-color: #282828">
              <div class="col-12 col-sm-6 p-0">
                <img class="img-fluid" src="~@/assets/images/new/banner-4.png">
              </div>
              <div class="col-12 col-sm-6 text-center my-auto p-5 p-sm-5">
                <h2 class="sub-title text-white">Efficiency</h2>
                <p class="desc text-white">Smart homes can control when things turn on and off, and keep track of exactly where and how much energy is used in your home.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-three pt-5">
      <div class="container">
        <div class="row mb-4 wow fadeInDown" data-wow-delay="0.2s">
          <div class="col-12 text-center">
            <h2 class="sub-title">How It Works</h2>
            <p class="desc">Each Home+ product becomes a building block for your new smart lifestyle.<br>
              Therefore we created smart trigger scenarios that allow you to use your <br>
              Home+ system the way you want it to function</p>
          </div>
        </div>
      </div>

      <!-- desktop view -->
      <div class="d-none d-sm-block d-md-block">
        <div class="img-section wow fadeInLeft" data-wow-delay="0.2s">
          <img class="img-fluid" src="~@/assets/images/new/section-banner-1-1.jpg">
          <div class="overlay"></div>
          <div class="row">
            <div class="col-12 section-banner-1-desc">
              <h3 class="banner-title text-white">Wifi Intelligent <br> Network Smart Lock</h3>
              <p class="banner-subtitle text-white">Wifi direct TUYA App control with advanced <br class="d-none d-sm-block">
                chip anti-interference bank-level network <br class="d-none d-sm-block">
                security encryption</p>
              <a href="https://shop.homeplus2u.com/product/a7h-smart-lock/" class="btn btn-link white-inverse-btn">Learn More</a>
            </div>
          </div>
        </div>

        <div class="img-section wow fadeInRight" data-wow-delay="0.2s">
          <img class="img-fluid" src="~@/assets/images/new/section-banner-2.png">
          <div class="overlay"></div>
          <div class="row">
            <div class="col-12 section-banner-2-desc">
              <h3 class="banner-title" style="color: #424242">Indoor Smart Camera</h3>
              <p class="banner-subtitle" style="color: #424242">An 1080p intelligent indoor camera with <br class="d-none d-sm-block">
                motion detection, omni-view PTZ, and <br class="d-none d-sm-block">
                excellent night vision effect.</p>
              <a href="https://shop.homeplus2u.com/product/lifesmart-camera/" class="btn btn-link grey-inverse-btn">Learn More</a>
            </div>
          </div>
        </div>

        <div class="img-section wow fadeInLeft" data-wow-delay="0.2s">
          <img class="img-fluid" src="~@/assets/images/new/section-banner-3.png">
          <div class="overlay"></div>
          <div class="row">
            <div class="col-12 section-banner-3-desc">
              <h3 class="banner-title text-white">Nature <br> Control Pad</h3>
              <p class="banner-subtitle text-white">Integrated multiple ways to <br class="d-none d-sm-block"> interact with your house</p>
              <a href="https://shop.homeplus2u.com/product/nature-pad/" class="btn btn-link white-inverse-btn">Learn More</a>
            </div>
          </div>
        </div>

        <div class="img-section wow fadeInRight" data-wow-delay="0.2s">
          <img class="img-fluid" src="~@/assets/images/new/section-banner-4.png">
          <div class="overlay"></div>
          <div class="row">
            <div class="col-12 section-banner-3-desc">
              <h3 class="banner-title" style="color: #424242">Smart Devices</h3>
              <p class="banner-subtitle" style="color: #424242">Gives you total control of your home environment <br>
                and more ways to simplify your life.</p>
              <a href="https://shop.homeplus2u.com/product/environmental-sensor/" class="btn btn-link grey-inverse-btn">Learn More</a>
            </div>
          </div>
        </div>
      </div>

      <!-- mobile view -->
      <div class="d-block d-sm-none d-md-none">
        <div class="img-section wow fadeInLeft" data-wow-delay="0.2s">
          <img class="img-fluid" src="~@/assets/images/new/Homeplus_landing_05.jpg">
          <div class="overlay"></div>
          <div class="row">
            <div class="col-12 section-banner-1-desc">
              <h3 class="banner-title text-white">Wifi Intelligent <br> Network Smart Lock</h3>
              <p class="banner-subtitle text-white">Wifi direct TUYA App control with advanced <br class="d-none d-sm-block">
                chip anti-interference bank-level network <br class="d-none d-sm-block">
                security encryption</p>
              <a href="https://shop.homeplus2u.com/product/a7h-smart-lock/" class="btn btn-link white-inverse-btn">Learn More</a>
            </div>
          </div>
        </div>

        <div class="img-section wow fadeInRight" data-wow-delay="0.2s">
          <img class="img-fluid" src="~@/assets/images/new/Homeplus_landing_02.jpg">
          <div class="overlay"></div>
          <div class="row">
            <div class="col-12 section-banner-2-desc">
              <h3 class="banner-title" style="color: #424242">Indoor Smart Camera</h3>
              <p class="banner-subtitle" style="color: #424242">An 1080p intelligent indoor camera with <br class="d-none d-sm-block">
                motion detection, omni-view PTZ, and <br class="d-none d-sm-block">
                excellent night vision effect.</p>
              <a href="https://shop.homeplus2u.com/product/lifesmart-camera/" class="btn btn-link grey-inverse-btn">Learn More</a>
            </div>
          </div>
        </div>

        <div class="img-section wow fadeInLeft" data-wow-delay="0.2s">
          <img class="img-fluid" src="~@/assets/images/new/Homeplus_landing_03.jpg">
          <div class="overlay"></div>
          <div class="row">
            <div class="col-12 section-banner-3-desc">
              <h3 class="banner-title text-white">Nature <br> Control Pad</h3>
              <p class="banner-subtitle text-white">Integrated multiple ways to <br class="d-none d-sm-block"> interact with your house</p>
              <a href="https://shop.homeplus2u.com/product/nature-pad/" class="btn btn-link white-inverse-btn">Learn More</a>
            </div>
          </div>
        </div>

        <div class="img-section wow fadeInRight" data-wow-delay="0.2s">
          <img class="img-fluid" src="~@/assets/images/new/Homeplus_landing_04.jpg">
          <div class="overlay"></div>
          <div class="row">
            <div class="col-12 section-banner-3-desc">
              <h3 class="banner-title" style="color: #424242">Smart Devices</h3>
              <p class="banner-subtitle" style="color: #424242">Gives you total control of your home environment <br>
                and more ways to simplify your life.</p>
              <a href="https://shop.homeplus2u.com/product/environmental-sensor/" class="btn btn-link grey-inverse-btn">Learn More</a>
            </div>
          </div>
        </div>
      </div>

    </section>

    <section class="section-four pt-5 mt-0 mt-sm-0 wow fadeInDown" data-wow-delay="0.2s" :style="{ backgroundImage: `url(${BgImage})` }" style="background-size: cover;">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <p class="desc text-white">AN IMPRESSIVE</p>
            <h2 class="sub-title text-white">Portfolio Of Clients</h2>
          </div>

          <div class="row mt-5">
            <div class="col-12 text-center">
              <div class="row">
                <div class="col-12 col-sm-3 wow fadeInDown" data-wow-delay="0.4s">
                  <div class="card">
                    <div class="card-body">
                      <img class="img-fluid company-img" src="~@/assets/images/new/company-img-1.png">
                      <div class="row">
                        <div class="col-12 mt-5">
                          <div class="company-title">Sunway Vivaldi</div>
                          <a href="https://shop.homeplus2u.com/portfolio/sunway-vivaldi/" class="btn btn-link grey-inverse-btn">Learn More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-3 wow fadeInDown" data-wow-delay="0.6s">
                  <div class="card">
                    <div class="card-body">
                      <img class="img-fluid company-img" src="~@/assets/images/new/company-img-2.png">
                      <div class="row">
                        <div class="col-12 mt-5">
                          <div class="company-title">Eco Spring</div>
                          <a href="https://shop.homeplus2u.com/portfolio/eco-spring/" class="btn btn-link grey-inverse-btn">Learn More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-3 wow fadeInDown" data-wow-delay="0.8s">
                  <div class="card">
                    <div class="card-body">
                      <img class="img-fluid company-img" src="~@/assets/images/new/company-img-3.png">
                      <div class="row">
                        <div class="col-12 mt-5">
                          <div class="company-title">Ion Delemen</div>
                          <a href="https://shop.homeplus2u.com/portfolio/ion-delemengenting-highlands/" class="btn btn-link grey-inverse-btn">Learn More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-3 wow fadeInDown" data-wow-delay="0.8s">
                  <div class="card">
                    <div class="card-body">
                      <img class="img-fluid company-img" src="~@/assets/images/new/company-img-4.jpg">
                      <div class="row">
                        <div class="col-12 mt-5">
                          <div class="company-title">Mahkota Cheras</div>
                          <a href="https://shop.homeplus2u.com/portfolio/mahkota-cheras/" class="btn btn-link grey-inverse-btn">Learn More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="space"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--<section class="section-five">
      <div class="container">
        <div class="row mb-4">
          <div class="col-12 text-center">
            <h2 class="sub-title text-white">Contact Us</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <iframe style="width: 100%; height: 400px" id="gmap_canvas" src="https://maps.google.com/maps?q=A-2-23,%20Plaza%20Bukit%20Jalil,%20No%201,%20Persiaran%20Jalil%201,%20Bandar%20Bukit%20Jalil%2057000%20Kuala%20Lumpur&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center py-5" style="background-color: #fff">
            <a href="http://shop.homeplus2u.com/contact/" class="btn btn-link grey-inverse-btn">Contact Us Now</a>
          </div>
        </div>
      </div>
    </section>-->

    <section class="section-six pt-5">
      <div class="container">
        <div class="row mb-4">
          <div class="col-12 text-center">
            <h2 class="sub-title">Announcement</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-5">
            <carousel :per-page="1" :autoplay="true" :loop="true" :adjustable-height="true" :mouse-drag="true" :space-padding="5" :navigation-enabled="true">
              <slide>
                <div class="row">
                  <div class="col-12">
                    <img class="img-fluid" src="~@/assets/images/new/banner.png">
                  </div>
                </div>
              </slide>
              <slide>
                <div class="row">
                  <div class="col-12">
                    <a href="https://www.facebook.com/110734200586526/posts/283712189955392/?d=n" target="_blank"><img class="img-fluid" src="~@/assets/images/new/Brand_ambassador4.jpg"></a>
                  </div>
                </div>
              </slide>
              <slide>
                <div class="row">
                  <div class="col-12">
                    <h2 class="text-center">MOU Signing Ceremony</h2>
                  </div>
                  <div class="col-12">
                    <a target="_blank" href="https://www.facebook.com/homeplussmartliving/posts/262583248734953"><img class="img-fluid" src="~@/assets/images/new/MOU.jpg"></a>
                  </div>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
    </section>
    <section class="section-seven">
      <div class="container">
        <div class="row mb-4">
          <div class="col-12 text-center">
            <h2 class="sub-title">What Our Customer Say</h2>
          </div>

          <div class="col-12 mt-3">
            <a href="https://www.facebook.com/homeplussmartliving/reviews/" target="_blank"><img class="img-fluid review-logo" src="~@/assets/images/review-on-facebook.jpg"></a>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row mb-5">
          <div class="col-12 col-md-12 col-lg-4 col-xl-4" v-for="(review, index) in reviewsLimit" :key="index">
            <div class="card">
              <div class="card-body">
                <p class="font-weight-bold">Highly Recommended</p>
                <p>{{ review.review_text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <a target="_blank" href="https://wa.me/60102658891" style="text-decoration: none;
    margin: 1em auto;
    color: #fff;
    font-size: 0.9em;
    padding: 1em 1em 1em 3.5em;
    border-radius: 2em;
    font-weight: bold;
    background: #25d366 url(https://shop.homeplus2u.com/wp-content/uploads/2021/10/whatsapp-128.png) no-repeat 1.5em center;
    background-size: 1.6em;
    position: fixed;
    bottom: 10%;
    right: 1%;
    z-index: 9999;" rel="noopener">
      Contact Us</a>
  </div>
</template>


<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import leftArrow from '@/assets/images/left-arrow.png'
import rightArrow from '@/assets/images/right-arrow.png'

import { WOW } from 'wowjs';
import axios from 'axios';
import BgImage from '../assets/images/new/bg-4.png';
export default {
  name: "Home",
  components: {

  },
  data () {
    return {
      BgImage,
      isSubmit: false,
      message: '',
      success: false,
      reviews: []
    }
  },
  computed: {
    reviewsLimit() {
      return this.reviews.slice(0, 3)
    },
    navigationNext: function () {
      return "<img class='img-fluid' src='"+ rightArrow +"'>"
    },
    navigationPrev: function () {
      return "<img class='img-fluid' src='"+ leftArrow +"'>"
    }
  },
  mounted() {
    const wow = new WOW();

    wow.init();

    setTimeout(function(){
      this.$('#home').css('height', 'auto');
    }, 1000);

    if (localStorage.getItem('reviews')) {
      this.reviews = JSON.parse(localStorage.getItem('reviews'));
    }
    else {
      axios.get("https://graph.facebook.com/v12.0/110734200586526/ratings?access_token=EAACDefFpmUIBABZCxtGYnClzFxltWJg2279U3jmcHf53eBhwV15Ts74hqW2ZAwcqekYfDtdpZCMZCZBpZBTnxQuzVISYIX1QpR3uL6AyPzfZCpWbxPnU0sIlJxnUla79dhejvWKrJTuBae9ZCXan8BbrB47KXXQwGH2SZBvKX36K005uAKfFcRfTx3ZB5PQt8ZC1TMFLlGpfH7BogZDZD")
          .then((res) => {

            localStorage.setItem('reviews', JSON.stringify(res.data.data));
            this.reviews = JSON.parse(localStorage.getItem('reviews'));
          })
          .finally(() => {

          })
    }
  },
  methods: {
    submitForm () {
      let me = this;

      this.isSubmit = true;
      let form = document.getElementById('enquiryForm');
      let formData = new FormData(form);

      axios.post('https://shop.homeplus2u.com/wp-json/contact-form-7/v1/contact-forms/3989/feedback', formData)
          .then((res) => {

            if (res.data.status == 'mail_sent')
            {
              me.success = true;
            }
            else {
              me.success = false;
            }

            me.isSubmit = false;
            me.message = res.data.message;
          })
          .finally(() => {

          })
    }
  }
};
</script>
