<template>
  <div id="app">
    <navbar></navbar>
    <router-view />
    <my-footer></my-footer>

  </div>
</template>


<script>
  import Navbar from "@/views/Navbar";
  import MyFooter from "@/views/MyFooter";
  export default {
    name: "App",
    components: {
      Navbar,
      MyFooter
    }
  };
</script>
<style lang="scss">


</style>
